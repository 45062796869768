<template>
  <div>
    <div class="cardBox" v-if="!isStart">
      <div class="nm">续期信息</div>
      <div class="payment">
        <div>
          <div>名称：</div>{{transactionInfo.sealName||transactionInfo.commonName}}
        </div>
        <div>
          <div>序列号：</div>{{transactionInfo.signCertSn}}
        </div>
        <div>
          <div>续期年限：</div>{{transactionInfo.certDuration}}年
        </div>

      </div>
    </div>
    <div class="updating" v-else>
      <img :src="isFail?fillImg:isSuccess" alt="loadicon" />
      <h5>证书更新中</h5>
      <p>证书更新中，请不要关闭浏览器，不要拔出Ukey，不要做任何操作，以免造成证书更新失败。</p>
      <p>整个过程可能持续10-30秒，请耐心等待！</p>

    </div>
    <template v-if="!isStart">
      <el-button class="submitButn" @click="transactionProcess" type="primary">立即续期</el-button>
    </template>

  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  props: ['transactionInfo'],
  data () {
    return {
      isStart: false,
      isFail: false,
      isSuccess: require('@/assets/image/onlineRenewal/updating.gif'),
      fillImg: require('@/assets/image/onlineRenewal/updateFail.png'),
      certData: {}
    }
  },
  methods: {
    cancel () {
      this.$parent.cancel()
    },
    transactionProcess () {
      this.isStart = true
      this.$parent.Ukey.verifyUKey()
        .then(() => {
          request({
            url: '/portal/client/online/transaction/process',
            method: 'post',
            data: { transId: this.transactionInfo.transId },
            headers: this.$parent.headers
          })
            .then(res => {
              if (res && res.code === '0') {
                this.certData = res.data
                this.$parent.Ukey.writeCert(this.certData)
                  .then(res => {
                    if (res) {
                      this.getCertInfo(this.certData.signCertSn)
                    } else {
                      this.isFail = true
                    }
                  })
                  .catch(err => {
                    this.isFail = true
                    this.$message.error(err.message)
                  })
              } else {
                this.isStart = false
                this.$message.error(res.msg)
              }
            })
            .catch(() => {
              this.isStart = false
            })
        })
        .catch(err => {
          this.isStart = false
          this.$message.error(err.message)
        })
    },
    getCertInfo (certSn) {
      request({
        url: '/portal/client/online/transaction/get-info',
        method: 'GET',
        params: { certSn, transType: 3, isValid: false },
        headers: this.$parent.headers
      }).then(res => {
        if (res && res.code === '0') {
          let data = res.data
          data.curStatus = 11
          this.$parent.transactionInfo = data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    retryWrite () { }
  }
}
</script>

<style lang="scss" scoped>
.updating {
  box-sizing: border-box;
  border: 1px solid #dee2e8;
  padding: 24px;
  border-radius: 0;
  background: #fff;
  text-align: center;
  img {
    width: 45px;
    height: 46px;
  }
  h5 {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #333333;
    line-height: 21px;
    margin: 18px 0 14px;
  }
  p {
    font-size: 14px;
    color: #737887;
    line-height: 24px;
  }
  .el-button {
    width: 118px;
    height: 32px;
    background: #107dfc;
    border-radius: 2px;
    margin-top: 32px;
    &:disabled {
      background: #ebebeb;
      border-color: #ebebeb;
    }
    &:hover,
    &:active {
      background: $primaryColor;
      color: #fff;
    }
  }
}
</style>
