<template>
  <div>
    <template v-if="transactionInfo.signCertSn">
      <template v-if="transactionInfo.sealNo">
        <div class="cardBox">
          <div class="nm">印章信息</div>
          <div class="certInfo">
            <div class="line">
              <div class="info">
                <div class="label">印章名称：</div>
                <div>{{transactionInfo.sealName}}</div>
              </div>

              <div class="info">
                <div class="label">所属项目：</div>
                <div>{{transactionInfo.projectName}}</div>
              </div>
            </div>
            <div class="info">
              <div class="label">印章类型：</div>
              <div>{{sealType[transactionInfo.sealType]}}<span v-if="transactionInfo.sealTypeExtend">{{'-' + transactionInfo.sealTypeExtend}}</span></div>
            </div>
            <div class="info">
              <div class="label">持有人：</div>
              <div>{{transactionInfo.commonName+'（'+transactionInfo.signCertSn+'）'}}</div>
            </div>
            <div class="info">
              <div class="label">制章人：</div>
              <div>{{transactionInfo.sealMakerCertCommonName+'（'+transactionInfo.sealMakerCertSn+'）'}}</div>
            </div>
            <div class="line">
              <div class="info">
                <div class="label">印章制作时间：</div>
                <div>{{transactionInfo.sealValidStartTime}}</div>
              </div>
              <div class="info">
                <div class="label">剩余有效期：</div>
                <div>{{sealIndate}}（{{transactionInfo.sealValidEndTime.split(' ')[0]}}）</div>
              </div>
            </div>

            <div class="info">
              <div class="label">印章版本号：</div>
              <div>{{transactionInfo.sealVersion}}</div>
            </div>
            <div class="info">
              <div class="label">签名算法标识：</div>
              <div>{{transactionInfo.signType}}</div>
            </div>
          </div>
        </div>
        <div class="cardBox">
          <div class="nm">印迹信息</div>
          <div class="certInfo">
            <div class="info">
              <div class="label">印记尺寸：</div>
              <div>{{`${transactionInfo.patternWidth}mm x ${transactionInfo.patternHeight}mm`}}</div>
            </div>
            <div class="info">
              <div class="label">印迹图样：</div>
              <div class="sealpatternImg"><img class="Img_1" :src="transactionInfo.patternFilePath" alt=""></div>
            </div>
          </div>
        </div>
      </template>
      <div class="cardBox" v-else>
        <div class="nm">证书信息</div>
        <div class="certInfo">
          <div class="line">
            <div class="info">
              <div class="label">证书序列号：</div>
              <div>{{transactionInfo.signCertSn}}</div>
            </div>
            <div class="info">
              <div class="label">所属项目：</div>
              <div>{{transactionInfo.projectName||'--'}}</div>
            </div>
          </div>
          <div class="line" v-if="transactionInfo.certType == 3 ||transactionInfo.certType == 2">
            <div class="info">
              <div class="label">机构名称/O：</div>
              <div>{{transactionInfo.organization||'--'}}</div>
            </div>
            <div class="info">
              <div class="label">所属部门/OU：</div>
              <div>{{transactionInfo.organizationalUnit||'--'}}</div>
            </div>
          </div>
          <div class="info">
            <div class="label">名称/CN：</div>
            <div>{{transactionInfo.commonName||'--'}}</div>
          </div>
          <div class="info">
            <div class="label">国家/C：</div>
            <div>CN</div>
          </div>
          <div class="line">
            <div class="info">
              <div class="label">省份/S：</div>
              <div>{{transactionInfo.stateOrProvince||'--'}}</div>
            </div>
            <div class="info">
              <div class="label">城市/L：</div>
              <div>{{transactionInfo.locality||'--'}}</div>
            </div>
          </div>
          <div class="line">
            <div class="info">
              <div class="label">开始时间：</div>
              <div>{{transactionInfo.certStartTime}}</div>
            </div>
            <div class="info">
              <div class="label">结束时间：</div>
              <div>{{transactionInfo.certEndTime}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="noInfo">
      <img class="icon" src="@/assets/image/onlineRenewal/unlock_noInfo.png" />
      <div>暂无证书信息，请插入需要续期的Ukey，点击读取获取证书信息</div>
      <div>如有问题，请联系客服：400-030-2013</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['transactionInfo'],
  data () {
    return {
      sealType: {
        '1': '个人章',
        '2': '法人单位章',
        '3': '职务章',
        '4': '部门章'
      }
    }
  },
  created () { },
  updated () { },
  computed: {
    sealIndate () {
      let startTime = new Date().getTime()
      let endTime = new Date(
        this.transactionInfo.sealValidEndTime.replace(/-/g, '/')
      ).getTime()
      let day = Math.floor((endTime - startTime) / (1000 * 3600 * 24))
      let years = Math.floor(Math.abs(day) / 365)
      return `${years ? years + '年' : ''}${day % 365}天`
    }
  }
}
</script>

<style scoped  lang="scss">
.certInfo {
  padding: 24px;
  line-height: 32px;
  padding-left: 12px 24px;
  font-size: 14px;
  color: #333333;
  letter-spacing: 1px;
  .info {
    display: flex;
    .label {
      flex-shrink: 0;
      display: inline-block;
      width: 120px;
      margin-right: 8px;
      color: #90939d;
      text-align: right;
    }
  }
  .line {
    display: flex;
    .info {
      width: 40%;
      margin-right: 5%;
    }
  }
  .sealpatternImg {
    width: 140px;
    height: 140px;
    padding: 14px;
    background: #fbfbfb;
    border: 1px solid #dee2e8;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      max-width: 140px;
      max-height: 140px;
      border: 1px dashed #bcd2ff;
    }
  }
}
.noInfo {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  padding: 72px 0;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #6d6e71;
  letter-spacing: 1px;
  .icon {
    width: 155px;
    height: 121px;
    margin: 8px auto;
  }
}
</style>
