// UKEY控件封装
import { InstanceIe, UnLockInstanceIe } from './ukeyMiddlewareInstanceIe'
const CONNECT_PARAM = {
  admin: {
    ws: 'ws://127.0.0.1:10016',
    clsid: 'DAE70BD8-447A-4728-AEB6-1F6E5208E5DC',
    InstanceIe
  },
  onlineUnlock: {
    ws: 'ws://127.0.0.1:10015',
    clsid: '84EE7E79-5408-43DA-98CA-165372EC5F11',
    InstanceIe: UnLockInstanceIe
  }
}
const ERRMSG = {
  0x00000000: '成功',
  0x0A000001: '失败',
  0x0A000002: '异常错误',
  0x0A000024: '口令错误',
  0x0A000025: 'Ukey已锁死'
}
function tojson (str) {
  if (typeof str === 'string') {
    try {
      let obj = JSON.parse(str)
      if (typeof obj === 'object' && obj) {
        return obj
      } else {
        return str
      }
    } catch (e) {
      return str
    }
  } else {
    return str
  }
}

export const PARAM = {
  SGD_CERT_SERIAL: 0x00000002,
  SGD_CERT_DER_PUBLIC_KEY: 0x00000008,
  SGD_SM1_ECB: 0x00000101
}
export class UkeyMiddleware {
  // eslint-disable-next-line space-before-function-paren
  constructor(type) {
    if (isIe()) {
      this._cls_id = CONNECT_PARAM[type].clsid
      this.webOcxCtrl = null
    } else {
      this._url = CONNECT_PARAM[type].ws
      this.ws = ''
    }
    this.InstanceIe = CONNECT_PARAM[type].InstanceIe
  }
  connect () {
    return new Promise((resolve) => {
      if (isIe()) {
        try {
          let OcxCtrl = document.getElementById(this._cls_id)
          if (!OcxCtrl) {
            let pluginEmbed = document.createElement('OBJECT')
            pluginEmbed.setAttribute('ID', this._cls_id)
            pluginEmbed.setAttribute('CLASSID', 'CLSID:' + this._cls_id)
            pluginEmbed.setAttribute('width', '0')
            pluginEmbed.setAttribute('height', '0')
            document.body.appendChild(pluginEmbed)
            OcxCtrl = document.getElementById(this._cls_id + '')
          }
          OcxCtrl.GetUkeyList()
          this.webOcxCtrl = new this.InstanceIe(OcxCtrl)
          resolve({ code: 0, data: '' })
        } catch (e) {
          console.log(e)
          resolve({ code: 1, data: '', msg: '豸信CA数字证书助手链接失败' })
        }
      } else {
        if (!(this.ws && this.ws.readyState === WebSocket.OPEN)) {
          try {
            this.ws = new WebSocket(this._url)
            this.ws.onopen = () => {
              resolve({ code: 0, data: '' })
            }
            this.ws.onclose = () => {
              this.ws.close()
            }
            this.ws.onerror = (err) => {
              console.log(err)
              resolve({ code: 1, data: err, msg: '豸信CA数字证书助手链接失败' })
            }
          } catch (err) {
            resolve({ code: 1, data: err, msg: '豸信CA数字证书助手链接失败' })
          }
        } else {
          resolve({ code: 0, data: '' })
        }
      }
    })
  }
  handle (fun, param) {
    return new Promise((resolve) => {
      if (isIe()) {
        let res = this.webOcxCtrl[fun](param)
        let err = this.webOcxCtrl.get('errCode')
        let errStr = this.webOcxCtrl.get('errStr')
        if (err === 0) {
          resolve({ code: 0, data: res })
        } else {
          console.log(fun, err)
          resolve({ code: err, data: res, msg: ERRMSG[err] || errStr || '' })
        }
      } else {
        let ws = this.ws
        let json = JSON.stringify({ function: fun, ...param })
        if (ws && ws.readyState === WebSocket.OPEN) {
          try {
            ws.send(json)
            ws.onmessage = function (event) {
              let res = tojson(event.data)
              if (res.code !== 0) console.log(fun, res.code, res.msg, param)
              res.msg = ERRMSG[res.code] || res.msg
              resolve(res)
            }
          } catch (err) {
            resolve({ code: 1, data: '', msg: '请求失败' })
          }
        } else {
          resolve({ code: 1, data: '', msg: '链接已断开' })
        }
      }
    })
  }
  get (key) {
    if (isIe()) {
      return this.webOcxCtrl.get(key)
    }
  }
}
function isIe () {
  return ('ActiveXObject' in window)
}
