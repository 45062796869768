// IE浏览器控件方法重构
// **************************************运营********************************
export class InstanceIe {
  // eslint-disable-next-line space-before-function-paren
  constructor (webOcxCtrl) {
    this.webOcxCtrl = webOcxCtrl
  }
  get (key) {
    return this.webOcxCtrl[key]
  }
  // 获取设备列表
  GetUkeyList () {
    try {
      let list = this.webOcxCtrl.GetUkeyList()
      let arr = []
      if (list) {
        list = list.split('&&&')
        for (let key of list) {
          arr.push({ 'uKey-alias': key })
        }
      }
      return arr
    } catch (e) {
      return null
    }
  }
  // 设备连接
  ConnectUkey (obj) {
    try {
      this.webOcxCtrl.ConnectUkey(obj['uKey-alias'], obj.applicationName, obj.containerName)
      return { devHandle: this.webOcxCtrl.devHandle }
    } catch (e) {
      return null
    }
  }
  // 设备格式化
  FormatUkey ({ devHandle, applicationName, containerName, adminPin, adminPinRetryCount, userPin, userPinRetryCount, createFileRights, asymAlgId, ulBitsLen }) {
    try {
      this.webOcxCtrl.FormatUkey(devHandle, applicationName, containerName, adminPin, adminPinRetryCount, userPin, userPinRetryCount, createFileRights, asymAlgId, ulBitsLen)
      return {}
    } catch (e) {
      return null
    }
  }
  // 设备登录
  UkeyLogin ({ devHandle, userPin, applicationName }) {
    try {
      this.webOcxCtrl.UkeyLogin(devHandle, userPin, applicationName)
      return { retryCount: this.webOcxCtrl.retryCount }
    } catch (e) {
      return null
    }
  }
  // 生成证书请求(P10)
  GenCertRequest ({ devHandle, dn, asymAlgId, ulBitsLen, hashAlgId, applicationName, containerName }) {
    try {
      let CSR = this.webOcxCtrl.GenCertRequest(devHandle, dn, asymAlgId, ulBitsLen, hashAlgId, applicationName, containerName)
      return { CSR }
    } catch (e) {
      return null
    }
  }
  // 导入证书及加密密钥
  ImportEncPrivKeyAndCerts ({ devHandle, symAlgId, base64SignCert, base64EncCert, base64WrappedEncKey, applicationName, containerName }) {
    try {
      this.webOcxCtrl.ImportEncPrivKeyAndCerts(devHandle, symAlgId, base64SignCert, base64EncCert, base64WrappedEncKey, applicationName, containerName)
      return {}
    } catch (e) {
      return null
    }
  }
  // 导入印章
  WriteSealToUkey ({ devHandle, sealBase64Content }) {
    try {
      this.webOcxCtrl.WriteSealToUkey(devHandle, sealBase64Content)
      return {}
    } catch (e) {
      return null
    }
  }
  // 导入多个印章
  WriteSealToUkeyEx ({ devHandle, sealBase64Content, sealIndex }) {
    console.log(sealIndex)
    try {
      this.webOcxCtrl.WriteSealToUkeyEx(devHandle, sealBase64Content, sealIndex)
      return {}
    } catch (e) {
      return null
    }
  }
  // 印章校验
  CheckExtSealValid ({ sealBase64Content }) {
    try {
      this.webOcxCtrl.CheckExtSealValid(sealBase64Content, 0)
      return {}
    } catch (e) {
      return null
    }
  }
  // 读取ukey内印章并解析
  ReadUkeySealAndParse ({ devHandle, sealIndex }) {
    try {
      this.webOcxCtrl.ReadUkeySealByIndexAndParse(devHandle, sealIndex)
      return {
        'header': {
          'ID': 'ES',
          'Vid': this.webOcxCtrl.vid,
          'version': this.webOcxCtrl.version
        },
        'esID': this.webOcxCtrl.esID,
        'property':
        {
          'type': this.webOcxCtrl.sealType,
          'name': this.webOcxCtrl.sealName,
          'certListType': this.webOcxCtrl.certListType,
          'certList': this.webOcxCtrl.certList,
          'createDate': this.webOcxCtrl.createDate,
          'validStart': this.webOcxCtrl.validStart,
          'validEnd': this.webOcxCtrl.validEnd
        },
        'picture':
        {
          'data': this.webOcxCtrl.picData,
          'type': this.webOcxCtrl.picType,
          'height': this.webOcxCtrl.picHeight,
          'width': this.webOcxCtrl.picWidth
        },
        'extDatas': {
          'extnID': this.webOcxCtrl.extnIDs,
          'critical': this.webOcxCtrl.extCritical,
          'extnValue': this.webOcxCtrl.extCriticals
        },
        'cert': this.webOcxCtrl.creatorCert
      }
    } catch (e) {
      return null
    }
  }
  // 读取UKey内电子印章原始数据
  ReadUkeySeal ({ devHandle }) {
    try {
      let sealRawData = this.webOcxCtrl.ReadUkeySeal(devHandle)
      return { sealRawData }
    } catch (e) {
      return null
    }
  }
  // 导出证书
  ExportMyUserCert ({ devHandle, CertType }) {
    try {
      let certContent = this.webOcxCtrl.ExportMyUserCert(devHandle, CertType)
      return { certContent }
    } catch (e) {
      return null
    }
  }
  // 获得证书信息
  GetCertSpecificInfo ({ base64Cert, infoType }) {
    try {
      let certInfo = this.webOcxCtrl.GetCertSpecificInfo(base64Cert, infoType)
      return { certInfo }
    } catch (e) {
      return null
    }
  }
  // 证书展示
  ShowCert ({ base64Cert }) {
    try {
      return this.webOcxCtrl.ShowCert(base64Cert)
    } catch (e) {
      return null
    }
  }
  // 获得设备信息
  GetDevSpecificInfo ({ devHandle }) {
    try {
      this.webOcxCtrl.GetDevSpecificInfo(devHandle)
      return {
        'Manufacturer': this.webOcxCtrl.manufacturer,
        'SerialNumber': this.webOcxCtrl.ukeySN
      }
    } catch (e) {
      return null
    }
  }
  //  Ukey签名
  SignDataWithUkey ({ devHandle, plainData, hashAlgId, derFlag, applicationName, containerName }) {
    try {
      let signValue = this.webOcxCtrl.SignDataWithUkey(devHandle, plainData, hashAlgId, derFlag, applicationName, containerName)
      return { signValue }
    } catch (e) {
      return null
    }
  }
  // 删除印章
  PrivOperlnWriteSeal ({ devHandle }) {
    try {
      this.webOcxCtrl.PrivOperlnWriteSeal(devHandle)
      return {}
    } catch (e) {
      return null
    }
  }
  // 关闭设备
  DisconnectUkey ({ devHandle }) {
    try {
      this.webOcxCtrl.DisconnectUkey(devHandle)
      return {}
    } catch (e) {
      return null
    }
  }
  // **************************************在线解锁********************************
  // 解锁
  unLockUkey ({ hDev, bscode }) {
    try {
      let signValue = this.webOcxCtrl.unLockUkey(hDev, bscode)
      return { signValue }
    } catch (e) {
      return null
    }
  }
  // 导出证书
  ExportUserCert ({ hDev }) {
    try {
      let signValue = this.webOcxCtrl.ExportUserCert(hDev)
      return { signValue }
    } catch (e) {
      return null
    }
  }
}
// **************************************在线解锁********************************
export class UnLockInstanceIe {
  // eslint-disable-next-line space-before-function-paren
  constructor (webOcxCtrl) {
    this.webOcxCtrl = webOcxCtrl
  }
  get (key) {
    return this.webOcxCtrl[key]
  }
  // 获取设备列表
  GetUkeyList () {
    try {
      let str = this.webOcxCtrl.GetUkeyList().split('&&&')
      let res = { 'ukeyName': str[0], 'CN': str[1], 'certSN': str[2], 'entry': '', 'hDev': '' }
      return res
    } catch (e) {
      console.log(e)
      return null
    }
  }
  // 设备连接
  ConnectUkey ({ ukeyName }) {
    try {
      this.webOcxCtrl.ConnectUkey(ukeyName)
      return { hDev: this.webOcxCtrl.devHandle }
    } catch (e) {
      return null
    }
  }
  // 解锁
  unLockUkey ({ hDev, bscode }) {
    try {
      let res = this.webOcxCtrl.unLockUkey(hDev, bscode)
      return res
    } catch (e) {
      return null
    }
  }
  // 导出证书
  ExportUserCert ({ hDev }) {
    try {
      let signCert = this.webOcxCtrl.ExportUserCert(hDev)
      return { signCert }
    } catch (e) {
      return null
    }
  }
}
