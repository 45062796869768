<template>
  <div class="onlineRenewal">
    <header>
      <div class="header-box">
        <svg-icon class="logo" icon-class="zxlogo" />
        <div>在线续期</div>
      </div>
    </header>
    <el-dialog title="选择Ukey" :visible.sync="choiceUkeyDialog" width="30%">
      <div class="formatUkey_sm">
        <div v-for="(item,index) in ukeys" :key="index">
          <el-radio v-model="choiceUkeyActive" :label="item['uKey-alias']">{{item['uKey-alias']|nameFilter}}</el-radio>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="choiceUkeyDialog=false">取消</el-button>
        <el-button size="small" :disabled="!choiceUkeyActive" type="primary" @click="choiceUkey">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog title="请输入口令" :destroy-on-close="true" :visible.sync="ukeyPinDialog" width="30%">
      <el-form v-if="ukeyPinDialog" :model="ukeyPinForm" label-position="top" size="small" ref="ukeyPinForm" label-width="100px" class="demo-ruleForm">
        <el-form-item prop="pin" :rules="[{ required: true, message: '请输入口令'}]">
          <el-input type="password" autocomplete="new-password" v-model.number="ukeyPinForm.pin"></el-input>
          <el-input v-show="false"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="ukeyPinDialog=false">取消</el-button>
        <el-button size="small" type="primary" @click="ukeyLogin">确认</el-button>
      </div>
    </el-dialog>
    <div class="main-box">
      <div class="title">
        <div>类型：在线续期</div>
        <template v-if="transactionInfo.transId">
          <div>业务单编号：{{transactionInfo.transNo}}</div>
          <div>提交时间：{{transactionInfo.transSubmitTime}}</div>
          <div>业务单状态：{{transactionInfo.curStatus| certTransStatus}}</div>
        </template>
      </div>
      <div class="steps">
        <div v-for="(item, index) in steps" class="step" :class="{active:item.step.includes(transactionInfo.curStatus)}" :key="index">
          <div class="icons">
            <div class="icon">
              <svg-icon :icon-class="item.icon" />
            </div>
            <svg-icon class="line" icon-class="arrows-line" />
          </div>
          <div class="label">{{ item.name }}</div>
        </div>
      </div>

      <template v-if="[-1].includes(transactionInfo.curStatus)">
        <div class="search">
          <div class="certSn">
            <span v-if="transactionInfo.signCertSn"> {{transactionInfo.signCertSn}}</span>
            <span v-else class="placeholder">证书序列号</span>
          </div>
          <el-button size="small" @click="connect" type="primary">读取</el-button>
        </div>
        <certInfo :transactionInfo="transactionInfo" />
        <template v-if="transactionInfo.signCertSn">
          <el-form label-position="right" label-width="100px" size="small" :model="formData" :rules="formRules" ref="form">
            <div class="cardBox">
              <div class="nm">经办人信息</div>
              <div class="form line">
                <el-form-item label="姓名" prop="agentName">
                  <el-input v-model="formData.agentName" placeholder="请输入经办人姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="agentMobile">
                  <el-input v-model="formData.agentMobile" placeholder="请输入经办人手机号"></el-input>
                </el-form-item>
                <el-form-item label="证件类型" prop="agentIdentType">
                  <el-select v-model="formData.agentIdentType" placeholder="请选择证件类型" size="small">
                    <el-option label="居民身份证" value="0"></el-option>
                    <el-option label="其他" value="Z"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="证件号码" ref="agentIdentNo" prop="agentIdentNo">
                  <el-input v-model="formData.agentIdentNo" placeholder="请输入经办人证件号码" :maxlength="formData.agentIdentType==='0'?18:30" size="small"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="agentEmail">
                  <el-input v-model="formData.agentEmail" placeholder="请输入经办人邮箱"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="cardBox">
              <div class="nm">申请信息</div>
              <div class="form">
                <el-form-item label="续期年限" prop="duration">
                  <el-select v-model="formData.duration" placeholder="请选择续期年限" size="small">
                    <el-option v-for="(item,index) in getYears(transactionInfo.postponeYears)" :key="index" :label="item +'年'" :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="附件" v-if="transactionInfo.needFile" prop="filePath">
                  <div class="uploadBox" :class="{nobg:!formData.filePath}">
                    <div class="file" v-if="formData.filePath">
                      <img class="icon" src="@/assets/image/onlineRenewal/pdf1.png" alt="">
                      {{ applyFileName }}
                    </div>
                    <el-upload :action="applyUpload" :headers="headers" :show-file-list="false" accept="application/pdf" :on-success="applyUploadSuccess" :before-upload="applyHandlePicture"
                      :on-error="hadlePoctureError">
                      <el-button v-if="formData.filePath" type="text">修改</el-button>
                      <el-button class="uploadButton" v-else type="text">上传申请材料扫描件</el-button>
                    </el-upload>
                  </div>
                </el-form-item>
              </div>

            </div>
          </el-form>
          <el-button class="submitButn" @click="submit" type="primary">立即申请</el-button>
        </template>
      </template>
      <template v-if="transactionInfo.curStatus===2">
        <div class="hint">
          <img class="icon" src="@/assets/image/onlineRenewal/unlock_success.png" />
          <div class="tt">
            资料审核中
            <div>请耐心等待，我们将在1~3个工作日完成审核。</div>
          </div>
        </div>
        <el-button class="submitButn" @click="cancel" type="primary">取消申请</el-button>
      </template>
      <template v-if="transactionInfo.curStatus===3">
        <div class="hint">
          <img class="icon" src="@/assets/image/onlineRenewal/unlock_noInfo.png" />
          <div class="tt">
            您的续期申请被驳回
            <div>{{transactionInfo.auditComment}}</div>
          </div>
        </div>
        <el-button class="submitButn" @click="reapply" type="primary">重新申请</el-button>
      </template>
      <template v-if="transactionInfo.curStatus===4">
        <div class="cardBox">
          <div class="nm">支付信息</div>
          <div class="payment">
            <div>
              <div>名称：</div>{{transactionInfo.sealName||transactionInfo.commonName}}
            </div>
            <div>
              <div>续期时间：</div>{{transactionInfo.certDuration}}年
            </div>
            <div>
              <div>服务费：</div>{{transactionInfo.amountTotal/transactionInfo.certDuration}}元
            </div>
            <div class="sum">
              <div>总计：</div>
              <span :class="{'decoration':transactionInfo.hasDiscount}">
                {{`￥${transactionInfo.amountTotal}（包含服务费￥${transactionInfo.amountTotal/transactionInfo.certDuration}）/年 x ${transactionInfo.certDuration}`}}
              </span>
            </div>
            <div v-if="transactionInfo.hasDiscount" class="sum discountTotal">
              <div>折扣价：</div><span class="val">{{`￥${transactionInfo.discountTotal}`}}</span>
            </div>
          </div>
        </div>

        <el-button class="submitButn" @click="peyment" type="primary">立即支付</el-button>
        <el-button class="cancelButn" @click="cancel" type="text">取消申请</el-button>
      </template>
      <renewal :transactionInfo="transactionInfo" v-if="[5,6].includes(transactionInfo.curStatus)" />
      <div class="hint" v-if="transactionInfo.curStatus===11">
        <img class="icon" src="@/assets/image/onlineRenewal/unlock_success.png" />
        <div class="tt">恭喜您，续期成功</div>
        <certInfo :transactionInfo="transactionInfo" />
      </div>
    </div>
    <peyment ref="peyment" />
  </div>
</template>

<script>
import { Ukey } from './ukey'
import request from '@/utils/request'
import { certTransStatus } from '@/utils/typeMatch'
import certInfo from './certInfo'
import peyment from './peyment'
import renewal from './renewal'
import {
  validName,
  validIDcard,
  validMobile,
  validEmail
} from '@/utils/validate'
export default {
  components: { certInfo, peyment, renewal },
  data() {
    return {
      applyUpload: '',
      steps: [
        {
          name: '填写资料',
          icon: 'service-step-zltx',
          step: [-1]
        },
        {
          name: '资料审核',
          icon: 'service-step-zlsh',
          step: [2, 3]
        },
        {
          name: '在线付款',
          icon: 'service-step-zxfk',
          step: [4]
        },
        {
          name: '在线更新',
          icon: 'service-step-zsgx',
          step: [5, 6]
        },
        {
          name: '业务完成',
          icon: 'service-step-ywc',
          step: [11]
        }
      ],
      loading: '',
      Ukey: null,
      ukeys: [],
      ukeyName: '',
      choiceUkeyActive: '',
      choiceUkeyDialog: false,
      ukeyPinDialog: false,
      ukeyPinForm: {
        pin: ''
      },
      transactionInfo: {
        curStatus: -1,
        signCertSn: ''
      },
      headers: {},
      formData: {
        duration: '',
        filePath: '',
        agentName: '',
        agentIdentType: '',
        agentIdentNo: '',
        agentMobile: '',
        agentEmail: ''
      },
      formRules: {
        duration: [
          { required: true, message: '请选择续期年限', trigger: 'change' }
        ],
        filePath: [
          { required: true, message: '请上传附件', trigger: 'change' }
        ],
        agentName: [
          { required: true, message: '请输入个人真实姓名', trigger: 'blur' },
          {
            validator: validName,
            message: '请输入您的真实姓名',
            trigger: 'blur'
          }
        ],
        agentIdentType: [
          { required: true, message: '请选择证件类型', trigger: 'change' }
        ],
        agentIdentNo: [
          { required: true, message: ' 请输入经办人证件号码', trigger: 'blur' },
          {
            validator: validIDcard,
            message: '请输入正确的18位身份证号码',
            trigger: 'blur'
          }
        ],
        agentMobile: [
          { required: true, message: '请输入经办人手机号码', trigger: 'blur' },
          {
            message: '请输入正确的个人手机号码',
            validator: validMobile,
            trigger: 'blur'
          }
        ],
        agentEmail: [
          {
            required: false,
            validator: validEmail,
            message: '请输入正确的电子邮箱',
            trigger: 'blur'
          }
        ]
      },
      hintShow: true
    }
  },
  mounted() {
    this.Ukey = new Ukey(this)
    this.applyUpload =
      this.CONFIG.baseURL + '/portal/client/online/transaction/upload'
    // this.connect()
    this.$alert(
      '1、所属项目需要审核的，平台会在1~3个工作日内完成审核，若审核不通过，可重新发起续期申请。<br/>2、在线续期是通过Ukey的口令登录来验证身份，请勿将口令随意告知他人。',
      '续期须知',
      {
        customClass: 'hintMessageBox',
        showClose: false,
        dangerouslyUseHTMLString: true,
        confirmButtonText: '同意并进行续期'
      }
    ).then(() => {})
  },
  methods: {
    connect() {
      this.Ukey.connect()
        .then(lists => {
          if (lists.length > 1) {
            this.ukeys = lists
            this.choiceUkeyDialog = true
          } else {
            this.ukeyName = lists[0]['uKey-alias']
            this.ukeyPinDialog = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    choiceUkey() {
      this.ukeyName = this.choiceUkeyActive
      this.choiceUkeyDialog = false
      this.ukeyPinDialog = true
      this.ukeyPinForm = {
        pin: ''
      }
    },
    ukeyLogin() {
      this.$refs.ukeyPinForm.validate(valid => {
        if (valid) {
          this.Ukey.ukeyLogin(this.ukeyName, this.ukeyPinForm.pin)
            .then(token => {
              this.headers = {
                Authorization: 'bearer ' + token,
                'lb-s-version': 'zxl'
              }
              this.getCertInfo()
            })
            .catch(err => {
              if (err.message && !err.response) {
                this.$message.error(err.message)
              }
            })
        }
      })
    },
    getCertInfo() {
      request({
        url: '/portal/client/online/transaction/get-info',
        method: 'GET',
        params: { certSn: this.Ukey.certSn, transType: 3, isValid: true },
        headers: this.headers
      }).then(res => {
        if (res && res.code === '0') {
          let data = res.data
          data.node.push(...[-1, 6, 11])
          this.steps = this.steps.filter(it => {
            let result = false
            for (let item of it.step) {
              if (data.node.includes(item)) {
                result = true
              }
            }
            return result
          })
          if (data.curStatus > 11) {
            data.curStatus = -1
          }
          this.ukeyPinDialog = false
          this.transactionInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getYears(years) {
      let arr = []
      if (years) {
        arr = years.split(',')
      }
      return arr
    },
    stepPass(step) {
      let r = true
      for (let item of step) {
        if (item >= this.transactionInfo.curStatus) {
          r = false
        }
      }
      return r
    },
    applyHandlePicture(file) {
      const isPDF = file && file.type === 'application/pdf'
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isPDF) {
        this.$message.error('请上传PDF文件')
        return false
      }
      if (!isLt20M) {
        this.$message.error('上传PDF文件大小不能超过 20M!')
        return false
      }
    },
    applyUploadSuccess(res) {
      // 文件上传成功时
      if (res.code === '0') {
        this.formData.filePath = res.data.filePath
        this.applyFileName = res.data.fileOldName
        this.$refs.form.validateField('filePath')
      } else {
        this.formData.filePath = ''
        this.applyFileName = ''
        this.$message.error(res.msg)
      }
    },
    hadlePoctureError() {
      this.$message.error('上传失败，请重试')
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          request({
            url: '/portal/client/online/transaction/submit',
            method: 'post',
            data: { certSn: this.Ukey.certSn, ...this.formData },
            headers: this.headers
          }).then(res => {
            if (res.code === '0') {
              this.getCertInfo()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    reapply() {
      request({
        url: '/portal/client/online/transaction/reapply',
        method: 'post',
        data: { transId: this.transactionInfo.transId },
        headers: this.headers
      }).then(res => {
        if (res.code === '0') {
          this.getCertInfo()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    cancel() {
      this.$confirm('确认取消当前续期申请?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request({
          url: '/portal/client/online/transaction/cancel',
          method: 'post',
          data: { transId: this.transactionInfo.transId },
          headers: this.headers
        }).then(res => {
          if (res.code === '0') {
            this.formData = this.$options.data().formData
            this.transactionInfo = this.$options.data().transactionInfo
            this.$alert(
              '1、所属项目需要审核的，平台会在1~3个工作日内完成审核，若审核不通过，可重新发起续期申请。<br/>2、在线续期是通过Ukey的口令登录来验证身份，请勿将口令随意告知他人。',
              '续期须知',
              {
                customClass: 'hintMessageBox',
                showClose: false,
                dangerouslyUseHTMLString: true,
                confirmButtonText: '同意并进行续期'
              }
            ).then(() => {})
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    peyment() {
      this.$refs.peyment.payNow()
    }
  },
  filters: {
    nameFilter(val) {
      let item = val.split('##')[2]
      if (item === '') {
        return '空Key'
      } else {
        return item
      }
    },
    certTransStatus
  },
  watch: {
    'formData.agentIdentType': function(val) {
      this.$refs.form.clearValidate('agentIdentNo')
      if (val === '0') {
        this.formRules['agentIdentNo'] = [
          { required: true, message: ' 请输入经办人证件号码', trigger: 'blur' },
          {
            validator: validIDcard,
            message: '请输入正确的18位身份证号码',
            trigger: 'blur'
          }
        ]
      } else {
        this.formRules['agentIdentNo'] = [
          { required: true, message: ' 请输入经办人证件号码', trigger: 'blur' }
        ]
      }
    }
  }
}
</script>
<style lang="scss" >
.el-message-box__wrapper {
  .hintMessageBox {
    .el-message-box__header {
      .el-message-box__title {
        line-height: 60px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #303b50;
      }
    }
    .el-message-box__content {
      .el-message-box__container {
        line-height: 28px;
        font-size: 14px;
        color: #303b50;
        line-height: 28px;
      }
    }
    .el-message-box__btns {
      text-align: center;
      padding-top: 16px;
      padding-bottom: 24px;
      .el-button {
        line-height: 32px;
      }
    }
  }
}

.onlineRenewal {
  header {
    text-align: center;
    border-bottom: 1px #eaeaea solid;
    display: block;
    width: 100%;
    position: fixed;
    z-index: 9;
    background-color: #fff;
    .header-box {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333333;
      height: 60px;
      max-width: 1200px;
      padding: 0 10px;
      margin: 0 auto;
      .logo {
        padding-right: 24px;
        border-right: 1px #dfdfe2 solid;
        width: 122px;
        height: 26px;
        color: #3a6afe;
        margin-right: 24px;
      }
    }
  }
  .main-box {
    margin: 0 auto;
    text-align: center;
    padding: 60px 10px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    .steps {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #b9bbbf;
      margin-bottom: 42px;
      .step {
        white-space: nowrap;
        &:last-child {
          .line {
            display: none;
          }
        }
        &.active {
          .icons {
            color: #aeb5be;
            .icon {
              background: $primaryColor;
              color: #fff;
            }
          }
          .label {
            color: #303b50;
          }
          & ~ .step {
            .icons {
              color: #aeb5be;
            }
            .label {
              color: #6c737b;
            }
          }
        }
        .icons {
          line-height: 42px;
          display: flex;
          align-items: center;
          margin-bottom: 11px;
          position: relative;
          color: $primaryColor;
          .icon {
            height: 42px;
            width: 42px;
            font-size: 24px;
            text-align: center;
            margin: 0 12px;
            border-radius: 50%;
          }
          .line {
            height: 24px;
            width: 114px;
          }
        }

        .label {
          color: #333333;
          font-size: 14px;
          width: 66px;
          text-align: center;
        }
      }
    }
    .title {
      padding: 0 20px;
      border-bottom: 1px solid #dee2e8;
      color: #333;
      margin-bottom: 24px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div:first-child {
        font-size: 16px;
        line-height: 54px;
      }
    }
    .search {
      text-align: left;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      .el-input {
        width: 280px;
        margin-right: 8px;
      }
      .certSn {
        width: 280px;
        margin-right: 8px;
        background-color: #f5f7fa;
        border: 1px #e4e7ed solid;
        color: #c0c4cc;
        cursor: no-drop;
        line-height: 30px;
        border-radius: 4px;
        padding: 0 15px;
      }
    }

    .cardBox {
      border: 1px solid #dee2e8;
      border-radius: 0;
      background: #fff;
      margin-bottom: 24px;
      overflow: hidden;
      .nm {
        padding: 0 24px;
        background: #f5f8fc;
        font-size: 16px;
        text-align: left;
        color: #2a2b2c;
        line-height: 40px;
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 16px;
          background: $primaryColor;
          position: absolute;
          left: 14px;
          top: 12px;
        }
      }
    }

    .form {
      padding: 24px;
      padding-bottom: 2px;
      text-align: left;
      &.line {
        display: flex;
        flex-wrap: wrap;
        .el-form-item {
          width: 40%;
          margin-right: 10%;
        }
        .el-select {
          width: 100%;
        }
      }
      .el-select {
        width: auto;
      }
      .uploadBox {
        width: 80%;
        height: 38px;
        background: #f9fbfd;
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        justify-content: space-between;
        color: #107dfc;
        &.nobg {
          background: none;
          padding: 0;
        }
        .el-button {
          color: #107dfc;
          &.uploadButton {
            line-height: 30px;
            border: 1px solid #107dfc;
            padding: 0 12px;
            border-radius: 2px;
            &:hover {
              color: #499cfc;
            }
          }
        }
        .file {
          .icon {
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }
        }
      }
    }
    .payment {
      text-align: left;
      padding: 24px;
      line-height: 32px;
      padding-left: 12px 24px;
      font-size: 14px;
      color: #333333;
      letter-spacing: 1px;
      & > div {
        display: flex;
        &.sum {
          font-size: 16px;
          margin-top: 10px;
        }
        .decoration {
          text-decoration: line-through;
        }
        &.discountTotal {
          .val {
            font-size: 20px;
            color: #faad14;
          }
        }
        div {
          text-decoration: none;
          width: 120px;
          margin-right: 8px;
          color: #90939d;
          text-align: right;
        }
      }
    }
    .submitButn {
      margin: 0px auto;
      margin-top: 32px;
      width: 400px;
    }
    .cancelButn {
      display: block;
      margin: 0 auto;
    }
    .hint {
      text-align: center;
      padding: 40px 0;
      .icon {
        width: 155px;
        height: 121px;
      }
      .tt {
        font-size: 18px;
        color: #333333;
        line-height: 24px;
        letter-spacing: 1px;
        margin: 30px 0;
        div {
          margin-top: 10px;
          font-size: 16px;
          color: #90939d;
        }
      }
      .msg {
        background: #fafafb;
        border-radius: 4px;
        padding: 32px 100px;
        border: 1px solid #e2e2e2;
        font-size: 14px;
        color: #333333;
        line-height: 19px;
        letter-spacing: 1px;
        text-align: left;
        div {
          margin-bottom: 24px;
          &:last-child {
            margin: 0;
          }
          span {
            color: #f50000;
          }
        }
      }
    }
  }
}
</style>
<style  lang="scss"  scoped>
::v-deep .el-dialog {
  width: max-content;
  .formatUkey_sm {
    & > div {
      line-height: 24px;
      padding-bottom: 12px;
    }
  }
}
</style>
