<template>
  <div class="affirmance" v-if="show">
    <div class="paymentCode">
      <h2>
        微信支付
        <span class="el-icon-close" @click="closeTips"></span>
      </h2>
      <div class="codeShow">
        <div class="code-border" v-loading="paySrcLoading">
          <div class="onPayment" v-if="onPayment">
            <img class="icon" src="@/assets/image/onlineRenewal/success_blue.png" alt="付款中icon">
            <div>付款中，请稍后…</div>
          </div>
          <img :src="paySrc" alt="支付二维码" />
          <i class="time">{{ countdown | toTime }}后二维码自动刷新</i>
        </div>
        <p class="code-guide">
          <img src="@/assets/image/onlineRenewal/weixinsaoyisao.png" alt="引导图" />
        </p>
        <h3>支付完成后，若长时间未跳转请联系400-030-2013</h3>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  data () {
    return {
      show: false,
      onPayment: '',
      paySrcLoading: false,
      paySrc: '',
      countdown: 59,
      payType: 1,
      timer: null,
      interval: []
    }
  },
  methods: {
    closeTips () {
      for (let i = 0; i < this.interval.length; i++) {
        clearInterval(this.interval[i])
      }
      this.show = false
    },
    payNow () {
      this.paySrcLoading = true
      request({
        url: '/portal/client/online/transaction/generate_pay_qrcode',
        method: 'post',
        responseType: 'blob',
        headers: this.$parent.headers,
        params: {
          transId: this.$parent.transactionInfo.transId,
          payChannel: 1
        }
      }).then(({ data }) => {
        this.paySrcLoading = false
        if (data.type.indexOf('image/jpeg') !== -1) {
          this.paySrc = URL.createObjectURL(data)
          this.show = true
          this.pay()
          for (let i = 0; i < this.interval.length; i++) {
            clearInterval(this.interval[i])
          }
          let interval = setInterval(() => {
            this.pay()
          }, 1000)
          this.interval.push(interval)
        } else {
          this.$message.error('付款二维码获取失败，请刷新页面重试')
        }
      })
    },
    pay () {
      request({
        url: '/portal/client/online/transaction/get_trans_is_pay',
        method: 'get',
        async: true,
        skipErr: true,
        headers: this.$parent.headers,
        params: {
          transId: this.$parent.transactionInfo.transId
        }
      }).then(data => {
        this.onPayment = false
        if (data && Number(data.code) === 0) {
          for (let i = 0; i < this.interval.length; i++) {
            clearInterval(this.interval[i])
          }
          this.$message({
            type: 'success',
            message: '付款成功',
            onClose: () => { }
          })
          this.$parent.getCertInfo()
          this.closeTips()
        } else if (Number(data.code) === 1) {
          this.countdown = Math.ceil(data.data.remainMilliseconds / 1000)
        } else if (data.code === '-1') {
          this.payNow()
          return false
        } else if (Number(data.code) === 2) {
          this.payNow()
        } else if (data.code === '-2') {
          this.onPayment = true
        } else {
          for (let i = 0; i < this.interval.length; i++) {
            clearInterval(this.interval[i])
          }
          this.$confirm(data.msg, '提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            type: 'warning'
          }).then(() => {
            this.show = false
          })
        }
      })
    }
  },
  filters: {
    toTime (value) {
      let theTime = parseInt(value) // 需要转换的时间秒
      let theTime1 = 0 // 分
      let theTime2 = 0 // 小时
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
        }
      }
      let result = ''
      if (theTime >= 0) {
        result = '' + parseInt(theTime) + '秒'
      }
      if (theTime1 > 0) {
        result = '' + parseInt(theTime1) + '分' + result
      }
      if (theTime2 > 0) {
        result = '' + parseInt(theTime2) + '小时' + result
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.affirmance {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
}
.paymentCode {
  width: 599px;
  height: 422px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;

  h2 {
    line-height: 50px;
    background: #f5f8fc;
    color: #2a2b2c;
    font-size: 16px;
    text-align: center;
  }

  span {
    float: right;
    width: 28px;
    height: 50px;
    margin-right: 11px;
    background-size: 14px;
    line-height: 50px;
    cursor: pointer;
  }
  .codeShow {
    width: 100%;
    height: 372px;
    padding: 34px 78px 37px 78px;
    box-sizing: border-box;
    position: relative;

    h3 {
      width: 100%;
      color: #aeb5be;
      font-size: 12px;
      text-align: center;
      position: absolute;
      bottom: 30px;
      left: 0;
    }
  }

  .code-border {
    width: 164px;
    height: 164px;
    padding: 12px;
    float: left;
    margin-top: 31px;
    box-sizing: border-box;
    background: url('~@/assets/image/home/newborder.png') top left no-repeat;
    background-size: 164px;
    position: relative;

    img {
      width: 100%;
    }

    i {
      display: block;
      width: 100%;
      text-align: center;
      color: #2a2b2c;
      font-size: 14px;
      position: absolute;
      // bottom: -34px;
      top: 184px;
      left: 0;
    }
  }

  .code-guide {
    width: 190px;
    height: 264px;
    float: right;

    img {
      width: 100%;
    }
  }
}
</style>