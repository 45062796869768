import { UkeyMiddleware, PARAM } from '@/utils/ukeyMiddleware'
import { MessageBox } from 'element-ui'
import request from '@/utils/request'

function getErr (err) {
  return { message: err }
}
export class Ukey {
  // eslint-disable-next-line space-before-function-paren
  constructor (self) {
    this.self = self
    this.certInfo = {}
    this.applicationName = 'wellhope'
    this.containerName = 'LAB_USERCERT'
    this.ukeyMiddleware = new UkeyMiddleware('admin')
  }
  connect () {
    return this.ukeyMiddleware.connect().then(res => {
      if (res.code !== 0) {
        MessageBox.alert(
          '请检查数字证书管理工具是否正确安装或重新安装，新安装后请重启浏览器！',
          '未检测到数字证书控件',
          {
            confirmButtonText: '去下载'
          }
        ).then(() => {
          this.self.$util.downloadById(this.self.CONFIG.APPID)
        })
        throw getErr('Ukey链接失败')
      } else {
        return this.ukeyMiddleware.handle('GetUkeyList', {})
      }
    }).then(res => {
      if (res.code === 0 && res.data.length > 0) {
        return Promise.resolve(res.data)
      } else {
        MessageBox.alert(
          '读取证书失败，请重新插入或更换其他Ukey！',
          '未检测到Ukey',
          {
            confirmButtonText: '我知道了'
          }
        )
        throw getErr('未检测到Ukey')
      }
    })
  }
  ukeyLogin (ukeyName, userPin) {
    userPin = userPin + ''
    this.ukeyName = ukeyName
    return this.disconnectUkey().then(() => {
      return this.ukeyMiddleware.handle('ConnectUkey', { 'uKey-alias': ukeyName, applicationName: this.applicationName, containerName: this.containerName })
    }).then(res => {
      if (res.code !== 0) { throw getErr('Ukey链接失败') }

      this.devHandle = res.data.devHandle
      return this.ukeyMiddleware.handle('UkeyLogin', { devHandle: this.devHandle, userPin, appName: '' })
    }).then(res => {
      if (res.code !== 0) {
        if (res.code === 0x0A000024) {
          throw getErr('口令错误，请重新输入,剩余可验证次数' + res.data.retryCount + '次')
        } else {
          throw getErr(res.msg || '登录失败，请稍候再试')
        }
      }
      return this.ukeyMiddleware.handle('ExportMyUserCert', { devHandle: this.devHandle, CertType: 1 })
    }).then(res => {
      if (res.code !== 0) { throw getErr('读取证书信息失败') }
      let base64Cert = res.data.certContent
      this.base64Cert = base64Cert
      return this.ukeyMiddleware.handle('GetCertSpecificInfo', { base64Cert, infoType: PARAM.SGD_CERT_SERIAL })
    }).then(res => {
      if (res.code !== 0) { throw getErr('读取证书信息失败') }
      this.certSn = res.data.certInfo.toLowerCase()
      return request({
        url: '/auth/oauth/client/cert/message',
        method: 'post',
        data: { certSn: this.certSn },
        headers: {
          Authorization: 'Basic Y2xpZW50OnloY2ExMjM0NTY='
        }
      })
    }).then(res => {
      if (res.code === '0') {
        this.plainBase64Data = res.data.message
        return this.ukeyMiddleware.handle('GetCertSpecificInfo', { base64Cert: this.base64Cert, infoType: PARAM.SGD_CERT_DER_PUBLIC_KEY })
      } else {
        throw getErr('签名失败')
      }
    }).then(res => {
      if (res.code !== 0) { throw getErr('签名失败') }
      let publicKey = res.data.certInfo
      if (publicKey.length === 130 && publicKey.indexOf('04') === 0) {
        this.hashAlgId = 0x00000001
      } else {
        this.hashAlgId = 0x00000004
      }
      return this.ukeyMiddleware.handle('SignDataWithUkey',
        {
          devHandle: this.devHandle,
          plainData: this.plainBase64Data,
          hashAlgId: this.hashAlgId,
          derFlag: 1,
          applicationName: '',
          containerName: ''
        })
    }).then(res => {
      if (res.code !== 0) { throw getErr('签名失败') }
      return request({
        url: '/auth/oauth/client/user/cert/token',
        method: 'post',
        data: { certSn: this.certSn, signature: res.data.signValue },
        headers: {
          Authorization: 'Basic Y2xpZW50OnloY2ExMjM0NTY='
        }
      })
    }).then(res => {
      if (res && res.code === '0') {
        return Promise.resolve(res.data.access_token)
      } else {
        throw getErr(res.msg)
      }
    })
  }
  verifyUKey () {
    return this.ukeyMiddleware.handle('ExportMyUserCert', { devHandle: this.devHandle, CertType: 1 }).then(res => {
      if (res.code !== 0) { throw getErr('Ukey连接失败') }
      return this.ukeyMiddleware.handle('GetCertSpecificInfo', { base64Cert: res.data.certContent, infoType: PARAM.SGD_CERT_SERIAL })
    }).then(res => {
      let certSn = res.data.certInfo.toLowerCase()
      if (certSn && certSn === this.certSn.toLowerCase()) {
        return Promise.resolve()
      } else {
        throw getErr(`当前插入Ukey非办理续期业务所使用的，请更换Ukey`)
      }
    })
  }
  writeCert ({ signCertBase64, encCertBase64, enCertPrivateKey, sealDTO }) {
    return this.ukeyMiddleware.handle('ImportEncPrivKeyAndCerts', {
      devHandle: this.devHandle,
      symAlgId: PARAM.SGD_SM1_ECB,
      base64SignCert: signCertBase64,
      base64EncCert: encCertBase64,
      base64WrappedEncKey: enCertPrivateKey,
      applicationName: '',
      containerName: ''
    }).then(res => {
      if (res.code === 0) {
        if (sealDTO && sealDTO.length === 1) {
          return this.ukeyMiddleware.handle('WriteSealToUkey', { devHandle: this.devHandle, sealBase64Content: sealDTO[0].sealBase64 })
        } else {
          this.disconnectUkey()
          return Promise.resolve({ code: 0 })
        }
      } else {
        throw getErr(`证书写入失败`)
      }
    }).then(res => {
      if (res.code === 0) {
        this.disconnectUkey()
        return Promise.resolve(true)
      } else {
        throw getErr(`印章写入失败`)
      }
    })
  }
  disconnectUkey () {
    if (this.devHandle && this.devHandle !== '') {
      let devHandle = this.devHandle
      this.devHandle = ''
      return this.ukeyMiddleware.handle('DisconnectUkey', { devHandle })
    } else {
      return new Promise((resolve) => { resolve() })
    }
  }
}
